/* CSS Reset */
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  8. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* My own resets */
ul {
  padding-left: 1rem;
  margin-top: 1rem;
}

.social-svg {
  border-radius: 5px !important;
  border: 1px solid var(--primary);
}

/* Colors */
:root {
  --bg: #07080a;
  --primary: #f7f7f7;
  --secondary: #c6c5c5;
}

/* Light theme variables - Now done programmatically */
/* .light-mode {
  --bg: #c1c8c8;
  --primary: #07080a;
  --secondary: #333333;
} */

/* Load Inter font */
@font-face {
  font-family: 'Inter';
  src: url(./utils/Inter.ttf) format('truetype');
  font-weight: 400;
  font-display: swap;
}


body {
  background-color: var(--bg);
  color: var(--primary);
}

a, .link-button {
  display: inline-block;
  color: var(--primary);
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-shadow: 0 0 0 var(--primary);
  transition: all 0.3s ease;
  text-underline-offset: 1px;
}

/* TODO; Better Styling for links hover */

a:hover {
  color: var(--secondary);
  text-shadow: 1px 1px 1px var(--primary);
  transform: scale(1.02);
}

.link-button:hover {
  color: var(--secondary);
  text-shadow: 1px 1px var(--primary);
}

.light-mode a:hover:not(.social-icon), .light-mode .link-button:hover {
  transform: scale(1);
}

.mystery-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#mystery-button-1 button:nth-of-type(2) {
  margin-left: 1rem;
}

#mystery-button-2 {
  display: none;
}

.link-button {
  cursor: pointer;
  background: var(--bg);
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--primary);
  border-radius: 5px;
}


.App {
  display: flex;
  font-family: 'Inter', sans-serif;
  max-width: 1300px;
  min-height: 100vh;
  padding: 0 calc(12vw + 2rem);
  margin: 0 auto;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 6rem 0;
}

.experience {
  margin: 1.5rem 0;
  border: 1px solid var(--primary);
  border-radius: 0.5rem;
  padding: 1.2rem;
  font-size: 0.9rem;
  box-shadow: rgba(236, 236, 255, 0.25) 0px 10px 10px -5px, rgba(236, 236, 236, 0.3) 0px 8px 16px -10px;
  transition: all 0.3s ease;
  position: relative;
}

.experience:hover {
  box-shadow: rgba(255, 255, 255, 0.56) 0px 0px 10px 1px;
  color: #fff;
}

.experience>a {
  color: var(--primary);
  text-decoration: none;
}

.experience>a:hover {
  text-shadow: none;
}

.underlined {
  position: relative;
  display: inline-block;
}

.underlined span::after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: yellow;
  transition: all 0.3s ease;
}

.experience:hover .underlined span::after {
  height: 2px;
  width: 100%;
}

.align {
  align-items: center;
}

.justify {
  justify-content: center;
}

.heavy-p {
  font-size: large;
}

.title {
  margin-top: 3rem;
}

.title:not(:nth-of-type(1)) {
  margin-top: 1rem;
}

#welcome {
  width: 50%;
  height: 100%;
  min-height: 100vh;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

#socials p {
  font-size: larger;
}

#socials div {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  gap: 1rem;
}

#information {
  width: 50%;
  padding-top: 6rem;
}

#information p#introduction {
  text-align: justify;
}

footer {
  margin-top: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer a {
  text-shadow: none !important;
}

/* At less than 950px, make the layout responsive */
@media (max-width: 950px) {
  .App {
    flex-direction: column;
  }

  #mystery-button-1 {
    display: none;
  }

  #mystery-button-2 {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
  }

  #mystery-button-2 button {
    width: 100%;
  }

  #mystery-button-2 button:nth-of-type(1) {
    margin-right: 1rem;
  }

  #welcome {
    width: 100%;
    min-height: auto;
    position: static;
    padding-bottom: 2rem;
    ;
  }

  #socials {
    margin-top: 1rem;
  }

  #socials>p {
    display: none;
  }

  #information {
    width: 100%;
    padding-top: 2rem;
  }
}

@media (max-width: 500px) {
  .App {
    padding: 0 calc(5vw + 1rem);
  }
}